@media only screen and (max-width: 767px) {
    .main {
        padding: 0;
        text-align: center;
      }
      .main__wrap {
        max-width: 100%;
        margin-bottom: 50px;
      }
      .main__title {
        margin-bottom: 20px;
        box-align: left;
      }
      .main__text {
        margin: 0 auto 32px;
        line-height: 1.5;
      }
      .main__btns {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;
      }
      .main__btn:not(:last-child) {
        margin: 0 0 24px;
      }
      .main__bg {
        position: absolute;
        top: -62px;
        right: calc(50% - 550px);
        pointer-events: none;
        width:calc(50% - 550px);
      }
      .main__preview:nth-child(2) {
        top: 12%;
        right: 30%;
      }
      .main__preview:nth-child(3) {
        top: 7%;
        left: 8%;
      }
      .main__preview:nth-child(4) {
        top: 12%;
        right: 30%;
      }
      .main__preview:nth-child(5) {
        top: 12%;
        right: 30%;
      }
      .main__preview:nth-child(6) {
        top: 12%;
        right: 30%;
      }
      .main__info {
        display: none;
      }
      .main__list {
        display: none;
      }
      .main:not(:last-child) {
        margin-bottom: 48px;
      }
}
@media only screen and (max-width: 1180px) {
    .main {
        padding-top: 45px;
      }
      .main__wrap {
        max-width: 360px;
        margin-bottom: 155px;
      }
      .main__text {
        max-width: 260px;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 600;
      }
      .main__bg {
        position: absolute;
        top: -62px;
        pointer-events: none;
        /* width:calc(50% - 550px); */
      }
      .main__preview:nth-child(3) {
        top: 11%;
        left: 2%;
      }
      .main__logo:first-child {
        display: none;
      }
      .main:not(:last-child) {
        margin-bottom: 140px;
      }
}
@media only screen and (max-width: 1023px) {
    .main__wrap {
        margin-bottom: 100px;
      }
      .main__bg {
        position: absolute;
        top: -62px;
        right: calc(10% - 550px);
        pointer-events: none;
        width:calc(50% - 550px);
      }
      .main__list {
        margin: 0 -8px;
      }
      .main__logo {
        margin: 0 8px;
      }
      .main:not(:last-child) {
        margin-bottom: 112px;
      }
}
.main {
    position: relative;
    padding-top: 5px;
  }
  
  .main__wrap {
    max-width: 545px;
    margin-bottom: 138px;
  }
  
  .main__title {
    margin-bottom: 23px;
  }
  
  .main__title span {
    display: block;
  }
  
  .main__text {
    margin-bottom: 41px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    color: #6E757C;
  }
  
  .main__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -100px;
  }
  
  .main__btn:not(:last-child) {
    margin-right: 24px;
  }
  
  .main__bg {
    position: absolute;
    top: -62px;
    right: calc(50% - 550px);
    pointer-events: none;
  }
  
  .main__bg[data-aos] .main__preview {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
  }
  
  .main__bg[data-aos] .main__preview:nth-child(2) {
    opacity: 0;
    -webkit-transition-delay: 2.0s;
    -o-transition-delay: 2.0s;
    transition-delay: 2.0s;
  }
  
  .main__bg[data-aos].aos-animate .main__preview:nth-child(2) {
    opacity: 1;
  }
  
  .main__bg[data-aos] .main__preview {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
  }
  
  .main__bg[data-aos] .main__preview:nth-child(3) {
    opacity: 0;
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
  }
  
  .main__bg[data-aos].aos-animate .main__preview:nth-child(3) {
    opacity: 1;
  }
  
  .main__bg[data-aos] .main__preview {
    -webkit-transition: 6s;
    -o-transition: 6s;
    transition: 6s;
  }
  
  .main__bg[data-aos] .main__preview:nth-child(4) {
    opacity: 0;
    -webkit-transition-delay: 1.0s;
    -o-transition-delay: 1.0s;
    transition-delay: 1.0s;
  }
  
  .main__bg[data-aos].aos-animate .main__preview:nth-child(4) {
    opacity: 1;
  }
  
  .main__bg[data-aos] .main__preview {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
  }
  
  .main__bg[data-aos] .main__preview:nth-child(5) {
    opacity: 0;
    -webkit-transition-delay: 1.5s;
    -o-transition-delay: 1.5s;
    transition-delay: 1.5s;
  }
  
  .main__bg[data-aos].aos-animate .main__preview:nth-child(5) {
    opacity: 1;
  }
  
  .main__bg[data-aos] .main__preview {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
  }
  
  .main__bg[data-aos] .main__preview:nth-child(6) {
    opacity: 0;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
  }
  
  .main__bg[data-aos].aos-animate .main__preview:nth-child(6) {
    opacity: 1;
  }
  
  .main__preview:nth-child(2) {
    top: 30.5%;
    right: -6%;
    width: 18%;
  }
  
  .main__preview:nth-child(3) {
    top: 79%;
    left: 7%;
    width: 18%;
  }
  
  .main__preview:nth-child(4) {
    top: 88%;
    right: 30%;
    width: 18%;
  }
  
  .main__preview:nth-child(5) {
    top: 65.5%;
    right: -2%;
    width: 18%;
  }
  
  .main__preview:nth-child(6) {
    top: 47.5%;
    right: 88%;
    width: 18%;
  }
  
  .main__preview img {
    width: 100%;
  }
  
  .main__preview:not(:first-child) {
    position: absolute;
  }
  
  .main__info {
    margin-bottom: 24px;
    text-align: center;
    color: #6E757C;
  }
  
  .main__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -16px;
  }
  
  .main__logo {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 16px;
  }
  
  .main__logo img {
    max-width: 100%;
  }
  
  .main:not(:last-child) {
    margin-bottom: 200px;
  }

  [data-aos="animation-scale-top"] {
    -webkit-transform: translateY(20px) scaleY(1.2);
    -ms-transform: translateY(20px) scaleY(1.2);
    transform: translateY(20px) scaleY(1.2);
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
  }
  
  [data-aos="animation-scale-top"].aos-animate {
    -webkit-transform: translate(0) scaleY(1);
    -ms-transform: translate(0) scaleY(1);
    transform: translate(0) scaleY(1);
    opacity: 1;
  }

  .stage {
    margin-bottom: 16px;
  }