
.quality__body {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 600px;
  }
  
  .quality__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #2C353D;
    border-radius: 24px;
  }
  
  .quality__bg:before, .quality__bg:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: -webkit-linear-gradient(314.85deg, #AE80DC 1.17%, #DC83C3 31.88%, #8084DC 65.46%);
    background: -o-linear-gradient(314.85deg, #AE80DC 1.17%, #DC83C3 31.88%, #8084DC 65.46%);
    background: linear-gradient(135.15deg, #AE80DC 1.17%, #DC83C3 31.88%, #8084DC 65.46%);
  }
  
  .quality__bg:before {
    top: -280px;
    left: -280px;
    width: 560px;
    height: 560px;
    opacity: 0.6;
    -webkit-filter: blur(278.261px);
    filter: blur(278.261px);
  }
  
  .quality__bg:after {
    right: -194px;
    bottom: -181px;
    width: 390px;
    height: 390px;
    -webkit-filter: blur(160px);
    filter: blur(160px);
    opacity: 0.6;
  }
  
  .quality__gallery {
    position: absolute;
    top: 38%;
    left: calc(65% - 570px);
    z-index: 3;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .quality__preview:nth-child(2) {
    top: -43.5%;
    left: 25%;
    width: 31%;
  }
  
  .quality__preview:nth-child(3) {
    top: -8%;
    left: 1.5%;
    width: 35%;
  }
  
  .quality__preview:nth-child(3).aos-animate {
    -webkit-animation: up-down 4s 1.2s infinite;
    animation: up-down 4s 1.2s infinite;
  }
  
  .quality__preview:nth-child(4) {
    top: -24%;
    right: 20%;
    width: 42%;
  }
  
  .quality__preview:nth-child(4).aos-animate {
    -webkit-animation: up-down 4s 1.8s infinite;
    animation: up-down 4s 1.8s infinite;
  }
  
  .quality__preview:nth-child(5) {
    top: 32%;
    left: -8.5%;
    width: 26%;
  }
  
  .quality__preview img {
    width: 100%;
  }
  
  .quality__preview:not(:first-child) {
    position: absolute;
  }
  
  .quality__wrap {
    position: relative;
    z-index: 3;
    max-width: 455px;
    padding-right: 110px;
  }
  
  .quality__title {
    margin-bottom: 24px;
    color: #ffffff;
  }
  
  .quality__text {
    margin-bottom: 24px;
    color: #6A7986;
  }
  
  .quality__btn {
    -webkit-box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75);
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75);
    color: #ffffff;
  }
  
  .quality__btn:hover {
    background: #ffffff;
    color: #272E35;
  }
  
  .quality__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: -21px 8px 0;
    padding: 0 50px;
    border-radius: 0 0 24px 24px;
    background: -webkit-linear-gradient(359.67deg, #FEA989 0.02%, #99B4DB 98.92%);
    background: -o-linear-gradient(359.67deg, #FEA989 0.02%, #99B4DB 98.92%);
    background: linear-gradient(90.33deg, #FEA989 0.02%, #99B4DB 98.92%);
  }
  
  .quality__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    width: 33.333%;
    padding: 80px 30px 60px;
    text-align: center;
  }
  
  .quality__item:nth-child(2) {
    border-width: 0 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.15);
  }
  
  .quality__counter {
    position: relative;
    margin-bottom: 16px;
    font-family: 'Poppins', sans-serif;
    font-size: 72px;
    line-height: 1.11111;
    font-weight: 600;
  }
  
  .quality__sign {
    font-size: 32px;
    line-height: 1;
  }
  
  .quality__sign_up {
    position: relative;
    top: -34px;
  }
  
  .quality__category {
    padding: 0 10px;
    background: #ffffff;
    border-radius: 14px;
    -webkit-box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    font-weight: 700;
    line-height: 28px;
  }
  
  .quality:not(:last-child) {
    margin-bottom: 136px;
  }

  .body.dark .quality__bottom {
    color: #272E35;
  }

  @media only screen and (max-width: 767px) {
    .quality__center {
        padding: 0 12px;
      }
      .quality__body {
        min-height: auto;
        padding: 48px 20px 330px;
        text-align: center;
      }
      .quality__bg:after {
        display: none;
      }
      .quality__gallery {
        top: 108%;
        left: auto;
        bottom: 210px;
        -webkit-transform: translateX(-40%);
        -ms-transform: translateX(-40%);
        transform: translateX(-40%);
        width: 500px;
      }
      .quality__wrap {
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
      }
      .quality__title {
        margin-bottom: 16px;
      }
      .quality__bottom {
        display: block;
        margin: -21px 0 0;
        padding: 120px 56px 0;
      }
      .quality__item {
        width: 100%;
        padding: 24px 0 50px;
      }
      .quality__item:nth-child(2) {
        border-width: 2px 0;
      }
      .quality__counter {
        font-size: 56px;
        line-height: 1.14286;
      }
      .quality__sign_up {
        top: -26px;
      }
      .quality:not(:last-child) {
        margin-bottom: 48px;
      }
  }

  @media only screen and (max-width: 1024px) {
    .quality__body {
        min-height: 450px;
      }
      .quality__wrap {
        max-width: 340px;
        padding-right: 40px;
      }
      .quality__item {
        padding: 64px 24px 50px;
      }
      .quality__counter {
        font-size: 64px;
        line-height: 1.125;
      }
      .quality__sign {
        font-size: 26px;
      }
      .quality__sign_up {
        top: -30px;
      }
      .quality__gallery {
        top: -28%;
        left: calc(105% - 485px);
        width: 200px;
        height: 200px;
      }
  }
  @media only screen and (max-width: 1180px) {
    .quality__center {
        padding: 0 32px;
      }
      .quality__bg:before {
        left: -360px;
      }
      .quality__bg:after {
        right: -274px;
        bottom: -181px;
      }
      .quality__gallery {
        top: -28%;
        left: calc(70% - 485px);
        width: 100px;
        height: 100px;
      }
      .quality__preview:nth-child(5) {
        display: none;
      }
      .quality__wrap {
        max-width: 390px;
        padding-right: 45px;
      }
      .quality__bottom {
        padding: 0;
      }
      .quality:not(:last-child) {
        margin-bottom: 112px;
      }
  }