.header {
    padding: 50px 0;
    position: sticky;
    top: 0;
  }
  
  .header__center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .header__logo {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  
  .header__pic {
    width: 138px;
  }
  
  .header__pic_light {
    display: none;
  }
  
  .header__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 55px;
  }
  
  .header__preview {
    display: none;
    position: absolute;
    top: 50%;
    right: calc(50% - 803px);
    -webkit-transform: translateY(-51.5%);
    -ms-transform: translateY(-51.5%);
    transform: translateY(-51.5%);
    pointer-events: none;
  }
  
  .header__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: auto;
  }
  
  .header__link {
    font-weight: 600;
    color: #6E757C;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
  }
  
  .header__link:hover {
    color: #272E35;
  }
  
  .header__link.active {
    color: #FF6E30;
  }
  
  .header__link:not(:last-child) {
    margin-right: 48px;
  }
  
  .header__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .header__btn {
    min-width: auto;
  }
  
  .header__btn:not(:last-child) {
    margin-right: 8px;
  }
  
  .header__burger {
    position: relative;
    z-index: 12;
    display: none;
    width: 32px;
    height: 32px;
    margin-left: auto;
    text-align: center;
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .header__burger:before, .header__burger:after {
    display: block;
    width: 32px;
    height: 2px;
    background: #272E35;
    -webkit-transition: width .2s, background .2s, -webkit-transform .2s;
    transition: width .2s, background .2s, -webkit-transform .2s;
    -o-transition: transform .2s, width .2s, background .2s;
    transition: transform .2s, width .2s, background .2s;
    transition: transform .2s, width .2s, background .2s, -webkit-transform .2s;
  }
  
  .header__burger:before, .header__burger:after {
    content: '';
    margin: 8px auto;
  }
  
  .header__burger.active:before {
    -webkit-transform: translateY(5.5px) rotate(45deg);
    -ms-transform: translateY(5.5px) rotate(45deg);
    transform: translateY(5.5px) rotate(45deg);
  }
  
  .header__burger.active:after {
    -webkit-transform: translateY(-5.5px) rotate(-45deg);
    -ms-transform: translateY(-5.5px) rotate(-45deg);
    transform: translateY(-5.5px) rotate(-45deg);
  }
  
  .header:not(:last-child) {
    margin-bottom: 20px;
  }
  
  body.dark .header__pic_dark {
    display: none;
  }
  
  body.dark .header__pic_light {
    display: inline-block;
  }
  
  body.dark .header__link:hover {
    color: #ffffff;
  }
  
  body.dark .header__link.active {
    color: #FF6E30;
  }
  
  body.dark .header__burger:before, body.dark .header__burger:after {
    background: #ffffff;
  }

  @media only screen and (max-width: 474px) {
    .header__preview {
      display: block;
      top: auto;
      right: -300px;
      width: 650px;
      bottom: 100px;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    .header__preview img {
      width: 100%;
    }
    .header__nav {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 0 0 auto;
    }
    .header__link {
      margin: 0;
    }
    .header__link:not(:last-child) {
      margin: 0 0 24px;
    }
    .main__bg {
      margin: 0 -160px 0 -100px;
    }
    .integrations__list {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1023px) {
    .header__wrapper {
        padding: 174px 32px 64px;
      }
      .header__link {
        color: rgba(39, 46, 53, 0.7);
      }
      .header__link.active {
        color: #FF6E30;
      }
      body.dark .header__link {
        color: rgba(255, 255, 255, 0.7);
      }
      body.dark .header__link.active {
        color: #FF6E30;
      } 
  }
  @media only screen and (max-width: 1179px) {
    .header {
        padding: 44px 0;
      }
      .header__logo {
        position: relative;
        z-index: 12;
      }
      .header__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        height: 100vh;
        padding: 174px 64px 64px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        overflow: hidden;
        background: #EEEFF2;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
      }
      .header__wrapper.visible {
        visibility: visible;
        opacity: 1;
      }
      .header__preview {
        display: block;
      }
      .header__nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-bottom: auto;
      }
      .header__link {
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        line-height: 1.25;
      }
      .header__link:not(:last-child) {
        margin: 0 0 32px;
      }
      .header__burger {
        display: inline-block;
      }
      .header:not(:last-child) {
        margin-bottom: 12px;
      }
      body.dark .header__wrapper {
        background: #1E252B;
      }
  }

  @media only screen and (max-width: 767px) {
    .header {
        padding: 48px 0 40px;
      }
      .header__center {
        padding: 0 32px;
      }
      .header__pic {
        width: 115px;
      }
      .header__wrapper {
        padding: 128px 32px 40px;
      }
      .header__preview {
        display: none;
      }
      .header__nav {
        position: relative;
        z-index: 3;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: -16px 0 auto -24px;
      }
      .header__link {
        margin: 16px 0 0 24px;
        font-size: 24px;
        color: #272E35;
      }
      .header__link.active {
        color: #FF6E30;
      }
      .header__link:not(:last-child) {
        margin: 16px 0 0 24px;
      }
      .header__btns {
        position: relative;
        z-index: 3;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        width: calc(100% + 16px);
        margin: 0 -8px;
      }
      .header__btn {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 12px);
        flex: 0 0 calc(50% - 12px);
        min-width: calc(50% - 12px);
        margin: 0 6px;
      }
      .header__btn:not(:last-child) {
        margin-right: 6px;
      }
      .header:not(:last-child) {
        margin: 0;
      }
      body.dark .header__link {
        color: #ffffff;
      }
      body.dark .header__link.active {
        color: #FF6E30;
      }
  }